*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #dcd9d0;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.\!container {
  width: 100% !important;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .\!container {
    max-width: 640px !important;
  }

  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .\!container {
    max-width: 768px !important;
  }

  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .\!container {
    max-width: 1024px !important;
  }

  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .\!container {
    max-width: 1280px !important;
  }

  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .\!container {
    max-width: 1536px !important;
  }

  .container {
    max-width: 1536px;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-1 {
  inset: .25rem;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-\[14px\] {
  left: 14px;
}

.left-\[7px\] {
  left: 7px;
}

.left-full {
  left: 100%;
}

.right-0 {
  right: 0;
}

.right-2 {
  right: .5rem;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-8 {
  top: 2rem;
}

.-z-10 {
  z-index: -10;
}

.-z-\[1\] {
  z-index: -1;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[100\] {
  z-index: 100;
}

.z-\[1\] {
  z-index: 1;
}

.z-\[500\] {
  z-index: 500;
}

.z-\[95\] {
  z-index: 95;
}

.z-\[96\] {
  z-index: 96;
}

.z-\[97\] {
  z-index: 97;
}

.z-\[98\] {
  z-index: 98;
}

.z-\[999\] {
  z-index: 999;
}

.z-\[99\] {
  z-index: 99;
}

.m-0 {
  margin: 0;
}

.m-4 {
  margin: 1rem;
}

.m-auto {
  margin: auto;
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.-mx-3 {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.-mx-\[7px\] {
  margin-left: -7px;
  margin-right: -7px;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.-my-\[3px\] {
  margin-top: -3px;
  margin-bottom: -3px;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-\[4px\] {
  margin-left: 4px;
  margin-right: 4px;
}

.mx-\[8px\] {
  margin-left: 8px;
  margin-right: 8px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-2\.5 {
  margin-top: .625rem;
  margin-bottom: .625rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-\[9px\] {
  margin-top: 9px;
  margin-bottom: 9px;
}

.\!mt-0 {
  margin-top: 0 !important;
}

.-mt-2 {
  margin-top: -.5rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-\[10px\] {
  margin-bottom: 10px;
}

.mb-\[6px\] {
  margin-bottom: 6px;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-2\.5 {
  margin-left: .625rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-\[10px\] {
  margin-left: 10px;
}

.ml-\[28px\] {
  margin-left: 28px;
}

.ml-\[4px\] {
  margin-left: 4px;
}

.ml-\[6px\] {
  margin-left: 6px;
}

.ml-auto {
  margin-left: auto;
}

.mr-0 {
  margin-right: 0;
}

.mr-0\.5 {
  margin-right: .125rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-1\.5 {
  margin-right: .375rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-2\.5 {
  margin-right: .625rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-\[6px\] {
  margin-right: 6px;
}

.mr-auto {
  margin-right: auto;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-24 {
  height: 6rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-4 {
  height: 1rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[10px\] {
  height: 10px;
}

.h-\[15px\] {
  height: 15px;
}

.h-\[16px\] {
  height: 16px;
}

.h-\[174px\] {
  height: 174px;
}

.h-\[18px\] {
  height: 18px;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[3px\] {
  height: 3px;
}

.h-\[42px\] {
  height: 42px;
}

.h-\[45px\] {
  height: 45px;
}

.h-\[46px\] {
  height: 46px;
}

.h-auto {
  height: auto;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-0 {
  max-height: 0;
}

.max-h-20 {
  max-height: 5rem;
}

.max-h-28 {
  max-height: 7rem;
}

.max-h-32 {
  max-height: 8rem;
}

.max-h-64 {
  max-height: 16rem;
}

.max-h-96 {
  max-height: 24rem;
}

.max-h-\[116px\] {
  max-height: 116px;
}

.max-h-\[160px\] {
  max-height: 160px;
}

.max-h-\[16px\] {
  max-height: 16px;
}

.max-h-\[185px\] {
  max-height: 185px;
}

.max-h-\[224px\] {
  max-height: 224px;
}

.max-h-\[250px\] {
  max-height: 250px;
}

.max-h-\[280px\] {
  max-height: 280px;
}

.max-h-\[32px\] {
  max-height: 32px;
}

.max-h-\[calc\(100vh-100px\)\] {
  max-height: calc(100vh - 100px);
}

.max-h-\[calc\(var\(--radix-dropdown-menu-content-available-height\)-10px\)\] {
  max-height: calc(var(--radix-dropdown-menu-content-available-height)  - 10px);
}

.max-h-screen {
  max-height: 100vh;
}

.min-h-\[14px\] {
  min-height: 14px;
}

.min-h-\[16px\] {
  min-height: 16px;
}

.min-h-\[1px\] {
  min-height: 1px;
}

.min-h-\[20px\] {
  min-height: 20px;
}

.min-h-\[300px\] {
  min-height: 300px;
}

.min-h-\[320px\] {
  min-height: 320px;
}

.min-h-\[32px\] {
  min-height: 32px;
}

.min-h-\[3px\] {
  min-height: 3px;
}

.min-h-screen {
  min-height: 100vh;
}

.w-1 {
  width: .25rem;
}

.w-1\.5 {
  width: .375rem;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-24 {
  width: 6rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-4 {
  width: 1rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-80 {
  width: 20rem;
}

.w-9 {
  width: 2.25rem;
}

.w-9\/12 {
  width: 75%;
}

.w-\[16px\] {
  width: 16px;
}

.w-\[18px\] {
  width: 18px;
}

.w-\[1px\] {
  width: 1px;
}

.w-\[23px\] {
  width: 23px;
}

.w-\[3px\] {
  width: 3px;
}

.w-\[440px\] {
  width: 440px;
}

.w-\[640px\] {
  width: 640px;
}

.w-\[840px\] {
  width: 840px;
}

.w-\[var\(--radix-select-trigger-width\)\] {
  width: var(--radix-select-trigger-width);
}

.w-auto {
  width: auto;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-0 {
  min-width: 0;
}

.min-w-\[130px\] {
  min-width: 130px;
}

.min-w-\[14px\] {
  min-width: 14px;
}

.min-w-\[164px\] {
  min-width: 164px;
}

.min-w-\[16px\] {
  min-width: 16px;
}

.min-w-\[1px\] {
  min-width: 1px;
}

.min-w-\[270px\] {
  min-width: 270px;
}

.min-w-\[280px\] {
  min-width: 280px;
}

.min-w-\[32px\] {
  min-width: 32px;
}

.min-w-\[3px\] {
  min-width: 3px;
}

.min-w-\[592px\] {
  min-width: 592px;
}

.min-w-full {
  min-width: 100%;
}

.max-w-0 {
  max-width: 0;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-\[16px\] {
  max-width: 16px;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[270px\] {
  max-width: 270px;
}

.max-w-\[280px\] {
  max-width: 280px;
}

.max-w-\[32px\] {
  max-width: 32px;
}

.max-w-\[732px\] {
  max-width: 732px;
}

.max-w-\[80vw\] {
  max-width: 80vw;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-1 {
  flex: 1;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.origin-top-left {
  transform-origin: 0 0;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[-4px\] {
  --tw-translate-x: -4px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[15px\] {
  --tw-translate-x: 15px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[18px\] {
  --tw-translate-x: 18px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[2px\] {
  --tw-translate-x: 2px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-\[-1\] {
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.animate-\[fadeIn_0\.2s_forwards\] {
  animation: .2s forwards fadeIn;
}

.animate-\[fitToWidth_0\.4s_forwards\] {
  animation: .4s forwards fitToWidth;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-\[spin_0\.4s_linear_infinite\] {
  animation: .4s linear infinite spin;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: .3s forwards fade-in;
}

.animate-fade-in-output {
  animation: 1s cubic-bezier(.22, 1, .36, 1) forwards fade-in;
}

@keyframes hop {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-150%);
  }

  100% {
    transform: translateY(0);
  }
}

.animate-hop {
  animation: 1s ease-in-out infinite hop;
}

@keyframes point-right {
  0%, 100% {
    transform: translateX(-25%);
  }

  50% {
    transform: translateY(0);
  }
}

.animate-point-right {
  animation: 1.25s infinite point-right;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-slide-in {
  animation: .5s cubic-bezier(.22, 1, .36, 1) forwards slide-in;
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize-none {
  resize: none;
}

.resize {
  resize: both;
}

.list-inside {
  list-style-position: inside;
}

.list-decimal {
  list-style-type: decimal;
}

.list-disc {
  list-style-type: disc;
}

.auto-cols-auto {
  grid-auto-columns: auto;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.\!justify-start {
  justify-content: flex-start !important;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-3\.5 {
  gap: .875rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[10px\] {
  gap: 10px;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-\[10px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(10px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(10px * var(--tw-space-y-reverse));
}

.space-y-\[15px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(15px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(15px * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-khaki-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(238 236 232 / var(--tw-divide-opacity));
}

.divide-khaki-150 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 226 220 / var(--tw-divide-opacity));
}

.self-start {
  align-self: flex-start;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overscroll-contain {
  overscroll-behavior: contain;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.\!rounded-none {
  border-radius: 0 !important;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.rounded-\[14px\] {
  border-radius: 14px;
}

.rounded-\[3px\] {
  border-radius: 3px;
}

.rounded-\[4px\] {
  border-radius: 4px;
}

.rounded-\[5px\] {
  border-radius: 5px;
}

.rounded-\[8px\] {
  border-radius: 8px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-xl {
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.rounded-l {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-l-md {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-r {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-t-xl {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.rounded-tl-lg {
  border-top-left-radius: .5rem;
}

.rounded-tl-xl {
  border-top-left-radius: .75rem;
}

.rounded-tr-lg {
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-x-2 {
  border-left-width: 2px;
  border-right-width: 2px;
}

.\!border-b {
  border-bottom-width: 1px !important;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-r {
  border-right-width: 1px;
}

.border-r-0 {
  border-right-width: 0;
}

.border-t, .border-t-\[1px\] {
  border-top-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.\!border-indigo-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(88 30 235 / var(--tw-border-opacity)) !important;
}

.border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgb(34 148 81 / var(--tw-border-opacity));
}

.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(134 124 246 / var(--tw-border-opacity));
}

.border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgb(103 90 242 / var(--tw-border-opacity));
}

.border-khaki-100 {
  --tw-border-opacity: 1;
  border-color: rgb(238 236 232 / var(--tw-border-opacity));
}

.border-khaki-150 {
  --tw-border-opacity: 1;
  border-color: rgb(229 226 220 / var(--tw-border-opacity));
}

.border-khaki-200 {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 208 / var(--tw-border-opacity));
}

.border-khaki-25 {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 250 / var(--tw-border-opacity));
}

.border-khaki-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 198 185 / var(--tw-border-opacity));
}

.border-khaki-400 {
  --tw-border-opacity: 1;
  border-color: rgb(177 169 149 / var(--tw-border-opacity));
}

.border-khaki-50 {
  --tw-border-opacity: 1;
  border-color: rgb(246 245 243 / var(--tw-border-opacity));
}

.border-khaki-500 {
  --tw-border-opacity: 1;
  border-color: rgb(151 140 114 / var(--tw-border-opacity));
}

.border-khaki-600 {
  --tw-border-opacity: 1;
  border-color: rgb(117 108 87 / var(--tw-border-opacity));
}

.border-khaki-800 {
  --tw-border-opacity: 1;
  border-color: rgb(70 65 52 / var(--tw-border-opacity));
}

.border-khaki-900 {
  --tw-border-opacity: 1;
  border-color: rgb(47 43 35 / var(--tw-border-opacity));
}

.border-orange-600 {
  --tw-border-opacity: 1;
  border-color: rgb(171 80 37 / var(--tw-border-opacity));
}

.border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgb(168 50 195 / var(--tw-border-opacity));
}

.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgb(223 29 51 / var(--tw-border-opacity));
}

.border-teal-600 {
  --tw-border-opacity: 1;
  border-color: rgb(4 116 129 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgb(214 193 55 / var(--tw-border-opacity));
}

.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(173 153 42 / var(--tw-border-opacity));
}

.border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgb(142 124 33 / var(--tw-border-opacity));
}

.border-t-khaki-150 {
  --tw-border-opacity: 1;
  border-top-color: rgb(229 226 220 / var(--tw-border-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(14 14 14 / var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(222 254 231 / var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(184 254 204 / var(--tw-bg-opacity));
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(56 223 125 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(43 180 100 / var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 148 81 / var(--tw-bg-opacity));
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 239 254 / var(--tw-bg-opacity));
}

.bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(222 221 253 / var(--tw-bg-opacity));
}

.bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 255 / var(--tw-bg-opacity));
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 124 246 / var(--tw-bg-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(103 90 242 / var(--tw-bg-opacity));
}

.bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(88 30 235 / var(--tw-bg-opacity));
}

.bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(66 22 197 / var(--tw-bg-opacity));
}

.bg-khaki-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(238 236 232 / var(--tw-bg-opacity));
}

.bg-khaki-150 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 226 220 / var(--tw-bg-opacity));
}

.bg-khaki-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 217 208 / var(--tw-bg-opacity));
}

.bg-khaki-25 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-khaki-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 198 185 / var(--tw-bg-opacity));
}

.bg-khaki-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(177 169 149 / var(--tw-bg-opacity));
}

.bg-khaki-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(246 245 243 / var(--tw-bg-opacity));
}

.bg-khaki-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(117 108 87 / var(--tw-bg-opacity));
}

.bg-khaki-600\/20 {
  background-color: #756c5733;
}

.bg-khaki-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(70 65 52 / var(--tw-bg-opacity));
}

.bg-khaki-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(47 43 35 / var(--tw-bg-opacity));
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 232 230 / var(--tw-bg-opacity));
}

.bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 207 201 / var(--tw-bg-opacity));
}

.bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(171 80 37 / var(--tw-bg-opacity));
}

.bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 248 / var(--tw-bg-opacity));
}

.bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(231 208 242 / var(--tw-bg-opacity));
}

.bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 50 195 / var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 237 237 / var(--tw-bg-opacity));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 215 216 / var(--tw-bg-opacity));
}

.bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 168 171 / var(--tw-bg-opacity));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 248 248 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 76 88 / var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(223 29 51 / var(--tw-bg-opacity));
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(178 21 39 / var(--tw-bg-opacity));
}

.bg-teal-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(213 245 246 / var(--tw-bg-opacity));
}

.bg-teal-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(175 236 239 / var(--tw-bg-opacity));
}

.bg-teal-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 116 129 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/90 {
  background-color: #ffffffe6;
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 247 208 / var(--tw-bg-opacity));
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 240 144 / var(--tw-bg-opacity));
}

.bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 229 65 / var(--tw-bg-opacity));
}

.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 233 / var(--tw-bg-opacity));
}

.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(142 124 33 / var(--tw-bg-opacity));
}

.bg-opacity-20 {
  --tw-bg-opacity: .2;
}

.bg-opacity-30 {
  --tw-bg-opacity: .3;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.from-\[\#eae9f8\] {
  --tw-gradient-from: #eae9f8 var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #eae9f800 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-khaki-50 {
  --tw-gradient-via-position: ;
  --tw-gradient-to: #f6f5f300 var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), #f6f5f3 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-10\% {
  --tw-gradient-via-position: 10%;
}

.to-khaki-50 {
  --tw-gradient-to: #f6f5f3 var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.fill-khaki-200 {
  fill: #dcd9d0;
}

.fill-khaki-900 {
  fill: #2f2b23;
}

.fill-red-50 {
  fill: #fff8f8;
}

.object-cover {
  object-fit: cover;
}

.\!p-0 {
  padding: 0 !important;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[12px\] {
  padding: 12px;
}

.p-\[2\.5px\] {
  padding: 2.5px;
}

.p-\[30px\] {
  padding: 30px;
}

.p-\[4px\] {
  padding: 4px;
}

.\!py-\[2px\] {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[12px\] {
  padding-left: 12px;
  padding-right: 12px;
}

.px-\[5px\] {
  padding-left: 5px;
  padding-right: 5px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-\[11px\] {
  padding-top: 11px;
  padding-bottom: 11px;
}

.py-\[2px\] {
  padding-top: 2px;
  padding-bottom: 2px;
}

.py-\[3px\] {
  padding-top: 3px;
  padding-bottom: 3px;
}

.py-\[4px\] {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-\[5\.5px\] {
  padding-top: 5.5px;
  padding-bottom: 5.5px;
}

.py-\[5px\] {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}

.py-\[7\.5px\] {
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}

.py-\[7px\] {
  padding-top: 7px;
  padding-bottom: 7px;
}

.\!pb-\[2px\] {
  padding-bottom: 2px !important;
}

.\!pl-\[22px\] {
  padding-left: 22px !important;
}

.\!pl-\[24px\] {
  padding-left: 24px !important;
}

.\!pr-\[24px\] {
  padding-right: 24px !important;
}

.\!pt-\[4px\] {
  padding-top: 4px !important;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-\[15px\] {
  padding-bottom: 15px;
}

.pl-0 {
  padding-left: 0;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-2\.5 {
  padding-left: .625rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-3\.5 {
  padding-left: .875rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-\[11px\] {
  padding-left: 11px;
}

.pl-\[12px\] {
  padding-left: 12px;
}

.pl-\[20px\] {
  padding-left: 20px;
}

.pl-\[8px\] {
  padding-left: 8px;
}

.pr-0 {
  padding-right: 0;
}

.pr-0\.5 {
  padding-right: .125rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-28 {
  padding-right: 7rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-3\.5 {
  padding-right: .875rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-\[12px\] {
  padding-right: 12px;
}

.pr-\[3px\] {
  padding-right: 3px;
}

.pr-\[6px\] {
  padding-right: 6px;
}

.pr-\[8px\] {
  padding-right: 8px;
}

.pr-\[9px\] {
  padding-right: 9px;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-\[5px\] {
  padding-top: 5px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-\[11px\] {
  font-size: 11px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[8px\] {
  font-size: 8px;
}

.text-body {
  font-size: 1rem;
  line-height: 1.75rem;
}

.text-caption {
  font-size: .75rem;
  line-height: 1rem;
}

.text-h2 {
  font-size: 2.375rem;
  line-height: 3.125rem;
}

.text-h3 {
  font-size: 1.75rem;
  line-height: 2.625rem;
}

.text-h4 {
  font-size: 1.3125rem;
  line-height: 2rem;
}

.text-small {
  font-size: .875rem;
  line-height: 1.5rem;
}

.\!font-heavy {
  font-weight: 700 !important;
}

.font-bold {
  font-weight: 600;
}

.font-heavy {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-regular {
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.leading-3 {
  line-height: .75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-\[18px\] {
  line-height: 18px;
}

.leading-\[21px\] {
  line-height: 21px;
}

.leading-\[24px\] {
  line-height: 24px;
}

.leading-none {
  line-height: 1;
}

.leading-normal {
  line-height: 1.5;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-\[-0\.01em\] {
  letter-spacing: -.01em;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.\!text-khaki-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(177 169 149 / var(--tw-text-opacity)) !important;
}

.\!text-khaki-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(70 65 52 / var(--tw-text-opacity)) !important;
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(34 148 81 / var(--tw-text-opacity));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(17 85 46 / var(--tw-text-opacity));
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(134 124 246 / var(--tw-text-opacity));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(103 90 242 / var(--tw-text-opacity));
}

.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgb(88 30 235 / var(--tw-text-opacity));
}

.text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgb(66 22 197 / var(--tw-text-opacity));
}

.text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgb(45 21 157 / var(--tw-text-opacity));
}

.text-khaki-100 {
  --tw-text-opacity: 1;
  color: rgb(238 236 232 / var(--tw-text-opacity));
}

.text-khaki-25 {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

.text-khaki-300 {
  --tw-text-opacity: 1;
  color: rgb(203 198 185 / var(--tw-text-opacity));
}

.text-khaki-400 {
  --tw-text-opacity: 1;
  color: rgb(177 169 149 / var(--tw-text-opacity));
}

.text-khaki-500 {
  --tw-text-opacity: 1;
  color: rgb(151 140 114 / var(--tw-text-opacity));
}

.text-khaki-600 {
  --tw-text-opacity: 1;
  color: rgb(117 108 87 / var(--tw-text-opacity));
}

.text-khaki-700 {
  --tw-text-opacity: 1;
  color: rgb(94 86 69 / var(--tw-text-opacity));
}

.text-khaki-800 {
  --tw-text-opacity: 1;
  color: rgb(70 65 52 / var(--tw-text-opacity));
}

.text-khaki-900 {
  --tw-text-opacity: 1;
  color: rgb(47 43 35 / var(--tw-text-opacity));
}

.text-orange-600 {
  --tw-text-opacity: 1;
  color: rgb(171 80 37 / var(--tw-text-opacity));
}

.text-orange-700 {
  --tw-text-opacity: 1;
  color: rgb(138 67 33 / var(--tw-text-opacity));
}

.text-pink-600 {
  --tw-text-opacity: 1;
  color: rgb(168 50 195 / var(--tw-text-opacity));
}

.text-pink-700 {
  --tw-text-opacity: 1;
  color: rgb(135 45 153 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(249 76 88 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(223 29 51 / var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(178 21 39 / var(--tw-text-opacity));
}

.text-teal-700 {
  --tw-text-opacity: 1;
  color: rgb(3 102 114 / var(--tw-text-opacity));
}

.text-teal-800 {
  --tw-text-opacity: 1;
  color: rgb(5 80 92 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgb(142 124 33 / var(--tw-text-opacity));
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(85 72 17 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.underline-offset-4 {
  text-underline-offset: 4px;
}

.placeholder-khaki-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(177 169 149 / var(--tw-placeholder-opacity));
}

.placeholder-khaki-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(151 140 114 / var(--tw-placeholder-opacity));
}

.caret-khaki-500 {
  caret-color: #978c72;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-75 {
  opacity: .75;
}

.opacity-90 {
  opacity: .9;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_1px_2px_rgba\(0\,0\,0\,0\.08\)\] {
  --tw-shadow: 0 1px 2px #00000014;
  --tw-shadow-colored: 0 1px 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-profile-dropdown {
  --tw-shadow: 0px -8px 10px 0px #0000000d;
  --tw-shadow-colored: 0px -8px 10px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-transparent {
  --tw-shadow-color: transparent;
  --tw-shadow: var(--tw-shadow-colored);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.outline-1 {
  outline-width: 1px;
}

.outline-2 {
  outline-width: 2px;
}

.outline-offset-0 {
  outline-offset: 0px;
}

.outline-khaki-200 {
  outline-color: #dcd9d0;
}

.outline-khaki-400 {
  outline-color: #b1a995;
}

.outline-khaki-600 {
  outline-color: #756c57;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(14 14 14 / var(--tw-ring-opacity));
}

.ring-green-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(56 223 125 / var(--tw-ring-opacity));
}

.ring-green-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(43 180 100 / var(--tw-ring-opacity));
}

.ring-indigo-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(222 221 253 / var(--tw-ring-opacity));
}

.ring-indigo-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(162 155 248 / var(--tw-ring-opacity));
}

.ring-indigo-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(134 124 246 / var(--tw-ring-opacity));
}

.ring-indigo-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(103 90 242 / var(--tw-ring-opacity));
}

.ring-khaki-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(220 217 208 / var(--tw-ring-opacity));
}

.ring-khaki-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(246 245 243 / var(--tw-ring-opacity));
}

.ring-orange-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(171 80 37 / var(--tw-ring-opacity));
}

.ring-pink-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(201 129 223 / var(--tw-ring-opacity));
}

.ring-red-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(253 215 216 / var(--tw-ring-opacity));
}

.ring-red-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(249 76 88 / var(--tw-ring-opacity));
}

.ring-red-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(223 29 51 / var(--tw-ring-opacity));
}

.ring-teal-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(22 189 202 / var(--tw-ring-opacity));
}

.ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.ring-yellow-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(254 240 144 / var(--tw-ring-opacity));
}

.ring-yellow-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(214 193 55 / var(--tw-ring-opacity));
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.ring-opacity-60 {
  --tw-ring-opacity: .6;
}

.ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.ring-offset-indigo-300 {
  --tw-ring-offset-color: #bcb7fa;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[color\] {
  transition-property: color;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-300 {
  transition-delay: .3s;
}

.duration-100 {
  transition-duration: .1s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.\[a-zA-Z\:_\] {
  a-z-a--z: ;
}

.placeholder\:text-khaki-300::placeholder {
  --tw-text-opacity: 1;
  color: rgb(203 198 185 / var(--tw-text-opacity));
}

.placeholder\:text-khaki-400::placeholder {
  --tw-text-opacity: 1;
  color: rgb(177 169 149 / var(--tw-text-opacity));
}

.placeholder\:text-khaki-600::placeholder {
  --tw-text-opacity: 1;
  color: rgb(117 108 87 / var(--tw-text-opacity));
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:bottom-1:after {
  content: var(--tw-content);
  bottom: .25rem;
}

.after\:left-1:after {
  content: var(--tw-content);
  left: .25rem;
}

.after\:right-1:after {
  content: var(--tw-content);
  right: .25rem;
}

.after\:-z-\[1\]:after {
  content: var(--tw-content);
  z-index: -1;
}

.after\:h-\[1px\]:after {
  content: var(--tw-content);
  height: 1px;
}

.after\:bg-khaki-300:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(203 198 185 / var(--tw-bg-opacity));
}

.first\:pt-0:first-child {
  padding-top: 0;
}

.last\:mb-0:last-child {
  margin-bottom: 0;
}

.empty\:before\:text-khaki-500:empty:before {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: rgb(151 140 114 / var(--tw-text-opacity));
}

.empty\:before\:content-\[attr\(placeholder\)\]:empty:before {
  --tw-content: attr(placeholder);
  content: var(--tw-content);
}

.focus-within\:\!ring-1:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:border-khaki-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 208 / var(--tw-border-opacity));
}

.hover\:border-khaki-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(203 198 185 / var(--tw-border-opacity));
}

.hover\:border-khaki-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(246 245 243 / var(--tw-border-opacity));
}

.hover\:\!bg-indigo-100:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(240 239 254 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-indigo-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 255 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(134 124 246 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(103 90 242 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(88 30 235 / var(--tw-bg-opacity));
}

.hover\:bg-khaki-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(238 236 232 / var(--tw-bg-opacity));
}

.hover\:bg-khaki-150:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 226 220 / var(--tw-bg-opacity));
}

.hover\:bg-khaki-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 217 208 / var(--tw-bg-opacity));
}

.hover\:bg-khaki-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(246 245 243 / var(--tw-bg-opacity));
}

.hover\:bg-khaki-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(151 140 114 / var(--tw-bg-opacity));
}

.hover\:bg-khaki-600\/20:hover {
  background-color: #756c5733;
}

.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 76 88 / var(--tw-bg-opacity));
}

.hover\:bg-transparent:hover {
  background-color: #0000;
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:bg-white\/90:hover {
  background-color: #ffffffe6;
}

.hover\:bg-opacity-30:hover {
  --tw-bg-opacity: .3;
}

.hover\:bg-opacity-90:hover {
  --tw-bg-opacity: .9;
}

.hover\:text-indigo-500:hover {
  --tw-text-opacity: 1;
  color: rgb(134 124 246 / var(--tw-text-opacity));
}

.hover\:text-indigo-700:hover {
  --tw-text-opacity: 1;
  color: rgb(88 30 235 / var(--tw-text-opacity));
}

.hover\:text-khaki-600:hover {
  --tw-text-opacity: 1;
  color: rgb(117 108 87 / var(--tw-text-opacity));
}

.hover\:text-khaki-700:hover {
  --tw-text-opacity: 1;
  color: rgb(94 86 69 / var(--tw-text-opacity));
}

.hover\:text-khaki-800:hover {
  --tw-text-opacity: 1;
  color: rgb(70 65 52 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:outline-none:hover {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.hover\:ring-0:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.hover\:ring-1:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.hover\:ring-2:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.hover\:ring-khaki-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(238 236 232 / var(--tw-ring-opacity));
}

.hover\:ring-khaki-300:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(203 198 185 / var(--tw-ring-opacity));
}

.focus\:border-0:focus {
  border-width: 0;
}

.focus\:border-none:focus {
  border-style: none;
}

.focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(134 124 246 / var(--tw-border-opacity));
}

.focus\:border-khaki-200:focus {
  --tw-border-opacity: 1;
  border-color: rgb(220 217 208 / var(--tw-border-opacity));
}

.focus\:bg-khaki-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(238 236 232 / var(--tw-bg-opacity));
}

.focus\:bg-khaki-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(246 245 243 / var(--tw-bg-opacity));
}

.focus\:text-indigo-700:focus {
  --tw-text-opacity: 1;
  color: rgb(88 30 235 / var(--tw-text-opacity));
}

.focus\:underline:focus {
  text-decoration-line: underline;
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-indigo-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(222 221 253 / var(--tw-ring-opacity));
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(134 124 246 / var(--tw-ring-opacity));
}

.focus\:ring-transparent:focus {
  --tw-ring-color: transparent;
}

.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.focus\:ring-opacity-50:focus {
  --tw-ring-opacity: .5;
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus-visible\:text-khaki-900:focus-visible {
  --tw-text-opacity: 1;
  color: rgb(47 43 35 / var(--tw-text-opacity));
}

.focus-visible\:outline-none:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus-visible\:outline:focus-visible {
  outline-style: solid;
}

.focus-visible\:outline-indigo-500:focus-visible {
  outline-color: #867cf6;
}

.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-indigo-200:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(222 221 253 / var(--tw-ring-opacity));
}

.focus-visible\:ring-red-200:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(253 215 216 / var(--tw-ring-opacity));
}

.focus-visible\:ring-offset-1:focus-visible {
  --tw-ring-offset-width: 1px;
}

.active\:bg-indigo-100:active {
  --tw-bg-opacity: 1;
  background-color: rgb(240 239 254 / var(--tw-bg-opacity));
}

.active\:bg-indigo-600:active {
  --tw-bg-opacity: 1;
  background-color: rgb(103 90 242 / var(--tw-bg-opacity));
}

.active\:bg-indigo-800:active {
  --tw-bg-opacity: 1;
  background-color: rgb(66 22 197 / var(--tw-bg-opacity));
}

.active\:bg-khaki-100:active {
  --tw-bg-opacity: 1;
  background-color: rgb(238 236 232 / var(--tw-bg-opacity));
}

.active\:bg-khaki-150:active {
  --tw-bg-opacity: 1;
  background-color: rgb(229 226 220 / var(--tw-bg-opacity));
}

.active\:bg-khaki-200:active {
  --tw-bg-opacity: 1;
  background-color: rgb(220 217 208 / var(--tw-bg-opacity));
}

.active\:bg-khaki-800:active {
  --tw-bg-opacity: 1;
  background-color: rgb(70 65 52 / var(--tw-bg-opacity));
}

.active\:bg-red-700:active {
  --tw-bg-opacity: 1;
  background-color: rgb(178 21 39 / var(--tw-bg-opacity));
}

.active\:bg-transparent:active {
  background-color: #0000;
}

.active\:bg-white:active {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.active\:bg-opacity-30:active {
  --tw-bg-opacity: .3;
}

.active\:text-khaki-800:active {
  --tw-text-opacity: 1;
  color: rgb(70 65 52 / var(--tw-text-opacity));
}

.active\:text-khaki-900:active {
  --tw-text-opacity: 1;
  color: rgb(47 43 35 / var(--tw-text-opacity));
}

.active\:outline-2:active {
  outline-width: 2px;
}

.active\:ring-0:active {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.active\:ring-1:active {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.active\:ring-khaki-300:active {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(203 198 185 / var(--tw-ring-opacity));
}

.focus-visible\:active\:ring-offset-2:active:focus-visible {
  --tw-ring-offset-width: 2px;
}

.disabled\:bg-transparent:disabled {
  background-color: #0000;
}

.group:hover .group-hover\:visible {
  visibility: visible;
}

.group:hover .group-hover\:text-khaki-400 {
  --tw-text-opacity: 1;
  color: rgb(177 169 149 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-khaki-600 {
  --tw-text-opacity: 1;
  color: rgb(117 108 87 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-khaki-700 {
  --tw-text-opacity: 1;
  color: rgb(94 86 69 / var(--tw-text-opacity));
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:focus .group-focus\:visible {
  visibility: visible;
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.data-\[side\=bottom\]\:animate-slide-up[data-side="bottom"] {
  animation: .4s cubic-bezier(.16, 1, .3, 1) slide-up;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.data-\[side\=top\]\:animate-slide-down[data-side="top"] {
  animation: .4s cubic-bezier(.16, 1, .3, 1) slide-down;
}

@keyframes accordion-up {
  from {
    height: var(--radix-accordion-content-height);
  }

  to {
    height: 0;
  }
}

.data-\[state\=closed\]\:animate-accordion-up[data-state="closed"] {
  animation: .2s ease-out accordion-up;
}

@keyframes accordion-down {
  from {
    height: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
  }
}

.data-\[state\=open\]\:animate-accordion-down[data-state="open"] {
  animation: .2s ease-out accordion-down;
}

.data-\[highlighted\]\:bg-khaki-100[data-highlighted] {
  --tw-bg-opacity: 1;
  background-color: rgb(238 236 232 / var(--tw-bg-opacity));
}

.group[data-state="open"] .group-data-\[state\=open\]\:rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (prefers-color-scheme: dark) {
  .dark\:border-khaki-700 {
    --tw-border-opacity: 1;
    border-color: rgb(94 86 69 / var(--tw-border-opacity));
  }

  .dark\:bg-khaki-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(70 65 52 / var(--tw-bg-opacity));
  }

  .dark\:text-khaki-100 {
    --tw-text-opacity: 1;
    color: rgb(238 236 232 / var(--tw-text-opacity));
  }

  .dark\:text-khaki-300 {
    --tw-text-opacity: 1;
    color: rgb(203 198 185 / var(--tw-text-opacity));
  }

  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .dark\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }
}

@media (min-width: 408px) {
  .min-\[408px\]\:mb-\[-12px\] {
    margin-bottom: -12px;
  }
}

@media (min-width: 640px) {
  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .sm\:inline-flex {
    display: inline-flex;
  }

  .sm\:w-\[416px\] {
    width: 416px;
  }

  .sm\:max-w-xl {
    max-width: 36rem;
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:align-middle {
    vertical-align: middle;
  }
}

@media (min-width: 768px) {
  .md\:w-\[640px\] {
    width: 640px;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:rounded-lg {
    border-radius: .5rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:text-body {
    font-size: 1rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1024px) {
  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg\:w-\[864px\] {
    width: 864px;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:w-\[1024px\] {
    width: 1024px;
  }
}

.focus-within\:\[\&\:has\(\:focus-visible\)\]\:opacity-100:has(:focus-visible):focus-within {
  opacity: 1;
}

.\[\&\>\*\]\:p-0 > * {
  padding: 0;
}

.\[\&\>\*\]\:py-\[2px\] > * {
  padding-top: 2px;
  padding-bottom: 2px;
}

.\[\&\>span\:first-of-type\]\:max-w-\[calc\(var\(--radix-select-content-available-width\)-40px\)\] > span:first-of-type {
  max-width: calc(var(--radix-select-content-available-width)  - 40px);
}

.\[\&\>span\:first-of-type\]\:truncate > span:first-of-type {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.\[\&_li\]\:\!pt-\[4px\] li {
  padding-top: 4px !important;
}

/*# sourceMappingURL=index.828d7a2c.css.map */
